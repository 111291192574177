import queryString from "query-string"
import { LeadType } from "../types"
import sanitizeHtml from "sanitize-html"
import axios from "axios"

const buildCampaignName = function (): string | undefined {
    return process.env.GATSBY_CAMPAIGN_NAME
}

const buildCommunicationCampaignId = function (): string | undefined {
    // This is for future use
    return undefined
}

const buildClientRequestIPAddress = function (): string | undefined {
    return `0.0.0.0`
}

const buildQueryHash = function () {
    const { search } = window.location
    const parsed = queryString.parse(search)

    return {
        FullQueryString: search,
        ...parsed,
    }
}

const buildLeadPost = function (lead: LeadType): any {
    const urlParams = new URLSearchParams(window.location.search);
    return {
        ...(lead.firstName && { firstName: sanitizeHtml(lead.firstName) }),
        ...(lead.lastName && { lastName: sanitizeHtml(lead.lastName) }),
        ...(lead.email && { email: sanitizeHtml(lead.email) }),
        ...(lead.phoneNumber && { phoneNumber: sanitizeHtml(lead.phoneNumber) }),
        ...(lead.income && { annualIncome: sanitizeHtml(lead.income) }),
        ...(lead.dateOfBirth && { birthDate: lead.dateOfBirth }),
        ...(lead.state && { state: sanitizeHtml(lead.state) }),
        ...(lead.zipCode && { zipcode: sanitizeHtml(lead.zipCode) }),
        ...(lead.familySize && { familySize: lead.familySize }),


        ...(lead.clickDimensionsUserId && { ClickDimensionsUserId: lead.clickDimensionsUserId }),
        ...(lead.clickDimensionsSessionId && { ClickDimensionsSessionId: lead.clickDimensionsSessionId }),
        ...(lead.googleUserId && { GoogleUserId: lead.googleUserId }),
        ...(lead.webVisits && { WebVisits: lead.webVisits }),
        ...(lead.webPageViews && { WebPageViews: lead.webPageViews }),
        ...(lead.sourceType && { SourceType: lead.sourceType }),
        // @ts-ignore
        ...(lead.firstDate && { FirstDate: lead.firstDate }),
        ...(lead.firstSource && { FirstSource: lead.firstSource }),
        ...(lead.firstMedium && { FirstMedium: lead.firstMedium }),
        ...(lead.firstCampaign && { FirstCampaign: lead.firstCampaign }),
        ...(lead.landingPageFull && { LandingPageFull: lead.landingPageFull }),
        ...(lead.sourceReferrer && { SourceReferrer: lead.sourceReferrer }),
        ...(lead.landingPage && { LandingPage: lead.landingPage }),

        ...({ medium: urlParams.get('utm_medium') }),
        ...({ campaign: urlParams.get('utm_campaign') }),
        ...({ adgroup: urlParams.get('adgroup') }),
        ...({ term: urlParams.get('utm_term') }),
        ...({ GoogleAdGroupId: urlParams.get('adgroupid') }),
        ...({ GoogleCampaignId: urlParams.get('campaignid') }),
        ...({ ClickId: urlParams.get('msclkid') || urlParams.get('gclid') }),
        ...({ GoogleTargetId: urlParams.get('targetid') }),
        ...({ GoogleLocInterestMs: urlParams.get('loc_interest_ms') }),
        ...({ GoogleLocPhysicalMs: urlParams.get('loc_physical_ms') }),
        ...({ GoogleMatchType: urlParams.get('matchtype') }),
        ...({ GoogleDevice: urlParams.get('device') }),
        ...({ GoogleCreative: urlParams.get('creative') }),
        ...({ Keyword: urlParams.get('keyword') }),
        ...({ GooglePlacement: urlParams.get('placement') }),
        ...({ GoogleAdPosition: urlParams.get('adposition') }),
        ...({ Source: urlParams.get('utm_source') }),
        ...({ SourceDetails: lead.leadSourceDomain?.replace(`https://`, ``) }),
        ...({ SourceName: lead.leadSourceDomain?.replace(`https://`, ``) }),

        ...(lead.leadSourceDomain && { LeadSourceDomain: lead.leadSourceDomain?.replace(`https://`, ``) }), // Make sure this domain gets sent without protocol
        CampaignName: buildCampaignName(),
        CommunicationCampaignId: buildCommunicationCampaignId(),
        ipaddress: buildClientRequestIPAddress(),
        fullQueryString: buildQueryHash().FullQueryString,
        ...(lead.environment && { environment: lead.environment }),
    }
}

export const postLead = async function (lead: LeadType): Promise<boolean> {
    const data = buildLeadPost(lead)

    if (typeof window !== `undefined` && typeof window.datadogLogs !== `undefined`) {
        window.datadogLogs.logger.info(`track lead:`, data)
    }

    const response = await axios.post(`https://api.sureco.com/AQP/v2/api/Lead/CaptureLead`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const body = await response.data
    window.sessionStorage.setItem('LeadID', response.data)

    if (response.status < 200 || response.status > 299) {
        window.datadogLogs.logger.error(`submit lead error:`, body)
        throw new Error(body)
    }

    try {
        const response = await fetch(`/.netlify/functions/salesforce-post`, {
          method: `POST`,
          body: JSON.stringify(data),
        })
      
        const body = await response.text()
      
        if (response.status < 200 || response.status > 299) {
          console.log(`bad response for LeadPost Salesforce ${response.status}`)
          window.datadogLogs.logger.error(`submit salesforce error:`, body)
          return undefined
        }
      
        return body
      }
      catch (err) {
        window.datadogLogs?.logger.error(`submit salesforce error:`, body)
      }

    return true
}
